(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

/* global $ jQuery document IM */

(function ($) {
  var APP = {
    generateModelLayout: function generateModelLayout(index, data) {
      var divToAppend = data.gender === 1 ? '#men' : '#women';
      var chestLabel = data.gender === 1 ? 'Tórax' : 'Busto';

      if (index === 1) {
        $('#women .row').empty();
      }

      $(divToAppend + ' .row').append('\n        <div class="col-md-15 col-xs-6 col-sm-3 perfil" id="' + data.identity + '">\n          <div class="img">\n            <div class="overlay">\n              <div class="overlay-content">\n                <p>Altura: ' + data.height + ' m</p>\n                <p>Manequim: ' + data.bodySize + ' cm</p>\n                <p>' + chestLabel + ': ' + data.chest + '</p>\n                <p>Quadril: ' + data.hip + '</p>\n                <p>Cal\xE7ado: ' + data.footSize + '</p>\n              </div>\n            </div>\n            <img src="' + data.profilePicture + '" class="img-responsive">\n            <div class="over text-left">' + data.name + '</div>\n          </div>\n          <div class="text">\n            <p>Altura: ' + data.height + ' m</p>\n            <p>Manequim: ' + data.bodySize + ' m</p>\n            <p>Busto/T\xF3rax: ' + data.chest + '</p>\n            <p class="' + divHidden + '">Quadril: ' + data.hip + '</p>\n            <p>Cal\xE7ado: ' + data.footSize + '</p>\n          </div>\n        </div>\n      ');

      if (data.photos.length > 0) {
        var photos = data.photos.map(function (photo) {
          return { src: photo.path };
        });

        $('#' + data.identity).magnificPopup({
          items: photos,
          gallery: { enabled: true },
          type: 'image'
        });
      }
    }
  };

  $('.button-box-menu').click(function (e) {
    e.preventDefault();
    $('.box-menu.mobile').css('width', $(document).width() + 5);
    $('.box-menu').slideToggle('slow');
  });

  $(document).click(function (e) {
    if ($(e.target).closest('.box-login').length > 0 || $(e.target).closest('.box-menu').length > 0 || $(e.target).closest('.button-box-login').length > 0 || $(e.target).closest('.button-box-menu').length > 0) return;
    $('.box-login').slideUp();
    $('.box-menu').slideUp();
    $('.button-box-login .box').fadeOut();
  });

  if ($('#page-cidades').length > 0) {
    IM.getModels(function (response) {
      var data = response.data.data;

      $('#women .row').empty();

      $.each(data, APP.generateModelLayout);
    });

    $('#gender a').click(function (e) {
      e.preventDefault();
      $(this).tab('show');
    });
  }
})(jQuery);

},{}]},{},[1]);
